<template>
   <div>
      <div class="row">
         <div class="col-xxl-12 mb-2">
            <router-link
               :to="{
                  name: 'internship-notice',
                  params: { selectParams: selectParams }
               }"
               class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
            >
               <i class="fas fa-arrow-left mr-2"></i>
               <span class="d-none d-sm-inline">{{ $t('GENERAL.BACK') }}</span>
            </router-link>
         </div>
      </div>
      <v-data-table
         :headers="fields"
         :items="items"
         :loading="loadingTable"
         loading-text="Chargement en cours ..."
         responsive
         :hide-default-footer="true"
         no-data-text="Aucun résultat"
      >
         <template v-slot:item.date="{ item }">
            <div v-if="item.dateDebut === item.dateFin">{{ item.dateDebut | moment('Do MMMM YYYY') }}</div>
            <div v-else>{{ item.dateDebut | moment('Do MMMM YYYY') }} - {{ item.dateFin | moment('Do MMMM YYYY') }}</div>
         </template>
         <template v-slot:item.tauxSatisfaction="{ item }">
            <div v-if="item.nbStagiaire !== 0 && item.tauxSatisfaction">{{ item.tauxSatisfaction }}%</div>
            <div v-else>-</div>
         </template>
         <template v-slot:item.nbStagiaire="{ item }"><i class="fas fa-users mr-1"></i>{{ item.nbStagiaire }}</template>
         <template v-slot:item.action="{ item }">
            <router-link
               v-if="item.nbStagiaire !== 0 && item.tauxSatisfaction && item.tauxSatisfaction !== 0"
               :to="{
                  name: 'internship-notice.details',
                  params: {
                     acl_id: item.acl_id,
                     acl_soc: item.acl_soc,
                     customer_id: customer_id,
                     code_produit_id: code_produit_id,
                     selectParams: selectParams
                  }
               }"
            >
               <b-button class="btn btn-icon btn-circle btn-light btn-sm" v-b-tooltip.hover="'Voir le détail'">
                  <inline-svg src="/media/svg/icons/General/Visible.svg"></inline-svg>
               </b-button>
            </router-link>
         </template>
         <template v-slot:no-results>
            <span>Aucun résultat.</span>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import { GET_ACTIONS_BY_CLIENT_AND_PRODUCT_CODE } from '@/core/services/store/api-orion/action';

export default {
   props: {
      customer_id: {
         type: Number,
         required: true
      },
      code_produit_id: {
         type: Number,
         required: true
      },
      selectParams: {
         type: Object,
         required: false
      }
   },
   data() {
      return {
         fields: [
            { text: this.$t('INTERNSHIP_NOTICE.GENERAL.PRODUCT_CODE'), value: 'acl_pro_libelle' },
            { text: this.$t('INTERNSHIP_NOTICE.GENERAL.CUSTOMER'), value: 'clientName' },
            { text: this.$t('INTERNSHIP_NOTICE.GENERAL.DATE'), value: 'date', sortable: false },
            { text: this.$t('INTERNSHIP_NOTICE.GENERAL.NUMBER_OF_TRAINEES'), value: 'nbStagiaire' },
            { text: this.$t('INTERNSHIP_NOTICE.GENERAL.TAUX'), value: 'tauxSatisfaction' },
            { text: '', value: 'action' }
         ],
         items: [],
         loadingTable: true
      };
   },
   async mounted() {
      this.setPageTitle();
      if (!this.customer_id || !this.code_produit_id) {
         this.$router.push({ name: 'internship-notice' });
      } else {
         const params = {
            customer_id: this.customer_id,
            code_produit_id: this.code_produit_id
         };
         this.$store.dispatch(GET_ACTIONS_BY_CLIENT_AND_PRODUCT_CODE, params).then((result) => {
            this.items = result;
            this.loadingTable = false;
         });
      }
   },
   methods: {
      setPageTitle() {
         this.$store.dispatch(SET_PAGETITLE, this.$t('MENU.INTERNSHIP_NOTICE'));
      }
   }
};
</script>
